<template>
  <v-skeleton-loader
    class="mx-auto my-1"
    :max-width="maxWidth"
    transition="scale-transition"
    type="table"
  ></v-skeleton-loader>
</template>

<script>
  export default {
    name: 'skeleton',
    data() {
      return {
        maxWidth: '100%'
      }
    }
  }
</script>